import { m } from 'framer-motion';
import styled from 'styled-components';
import { Cta } from '../../../molecules/CallToAction/CallToAction.styles';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import shadow from '../../../styles/atoms/shadows';
import { font } from '../../../styles/atoms/typography';

export const Container = styled(m.div)`
  width: 100%;
  height: 100%;
  min-height: 540px;
  display: flex;
  flex-direction: column-reverse;
  background: ${colors.base.white};
  ${shadow('xxxl')}
  border-radius: 20px;
  overflow: hidden;

  ${atMinWidth.lg`
    grid-template-columns: 370px 1fr;
    display: grid;
    grid-template-columns: 570px 1fr;
    gap: 31px;
  `}
`;
export const HorizontalContainer = styled(m.div)`
  width: 100%;
  height: 100%;
  min-height: 540px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BottomTextContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 32px;

  .horizontal-card-subhead {
    ${font('text', 'lg', '500')}
    color: rgba(0, 0, 0, 0.8);
    p {
      font-family: CircularXX;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: -0.27px;
    }
  }
`;

export const Text = styled.div`
  width: 100%;
  height: 100%;
  padding: 32px;

  ${atMinWidth.sm`
    padding: 48px;
  `}

  ${atMinWidth.lg`
    padding: 70px;
  `}

  .tab-card-heading {
    ${font('display', 'sm', '700')}
    color: ${colors.gray[900]};
    margin-bottom: 16px;

    @media (min-width: 576px) {
      ${font('display', 'lg', '700')}
      margin-bottom: 24px;
    }
  }

  .tab-card-subhead {
    ${font('text', 'lg', '500')}
    color: ${colors.gray[600]};
    margin-bottom: 32px;
  }

  .tab-card-features-list {
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;
    width: 100%;
    height: auto;
    margin-bottom: 32px;

    li {
      ${font('text', 'md', '700')}
      color: ${colors.gray[900]};

      ${atMinWidth.sm`
        ${font('text', 'lg', '700')}
      `}
    }

    li:last-child {
      margin-bottom: 0px;
    }
  }

  .icon {
    display: block;
    flex: none;
    width: 20px;
    height: 20px;
    margin-top: 0.2rem;
  }
`;
export const ListItem = styled.li`
  gap: 16px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  ${font('text', 'lg', '700')}
  color: ${colors.gray[900]};

  img {
    margin-top: 4px;
  }
`;

export const HorizontalSmallImage = styled.div`
  width: 100%;
  border-radius: 0px;

  .gatsby-image-wrapper img {
    width: 100%;
    border-radius: 0px;
  }

  ${atMinWidth.lg`
    height: 100%;
  `}
`;

export const SmallHorizontalCard = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;

  .body-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 100%;
    gap: 16px;
  }

  .horizontal-card-subhead {
    ${font('text', 'lg', '500')};
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
  }

  .card-cta {
    ${Cta} {
      width: 142px;
      height: 27px;
      font-size: 18px;
      margin-top: 20px;
    }
  }
`;

export const SmallImage = styled.div`
  width: 100%;
  height: 285px;
  overflow: hidden;

  ${atMinWidth.lg`
    height: 100%;
  `}

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;
