import { AnimatePresence } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import CallToAction from '../../../molecules/CallToAction/CallToAction';
import Icon from '../../../styles/atoms/icons';
import {
  Container,
  Image,
  ListItem,
  Text,
} from '../styles/TabFeatureCard.styled';

const TabFeatureCard = ({ tab, activeTab, index, setActiveTab, totalTabs }) => {
  const [touchPosition, setTouchPosition] = useState(null);
  const {
    callToAction,
    cardBody,
    cardTitle,
    features,
    tabButtonName,
    tabCardImage,
  } = tab;

  const handleTouchStart = e => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = e => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5 && activeTab !== totalTabs - 1) {
      setActiveTab(activeTab + 1);
    }

    if (diff < -5 && activeTab !== 0) {
      setActiveTab(activeTab - 1);
    }

    setTouchPosition(null);
  };

  return (
    activeTab === index && (
      <AnimatePresence exitBeforeEnter>
        <Container
          key={tab.id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.3,
            default: { ease: 'easeInOut' },
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <Text>
            {cardTitle && <h3 className="tab-card-heading">{cardTitle}</h3>}
            {cardBody && <div className="tab-card-subhead">{cardBody}</div>}
            {features.length > 0 && (
              <ul className="tab-card-features-list">
                {features.map(feat => (
                  <ListItem key={feat.id} className="tab-card-feature">
                    <Icon className="icon" id="checkmark-blue" isImage />
                    {feat.feature}
                  </ListItem>
                ))}
              </ul>
            )}
            {callToAction && (
              <CallToAction
                link={callToAction.url}
                value={callToAction.label}
                variant={callToAction.variant}
                color="primary"
              />
            )}
          </Text>
          <Image>
            <GatsbyImage
              image={tabCardImage.gatsbyImageData}
              alt={tabButtonName}
            />
          </Image>
        </Container>
      </AnimatePresence>
    )
  );
};

export default TabFeatureCard;
